import { IconButton } from "@material-tailwind/react";
import { MenuCustomAnimation } from "../../components/Dropdown";

export function Home() {
  return (
    <section className="mx-auto px-auto py-32 ">
      <div className="flex flex-col md:flex-row px-12 ">
        <div className="w-full text-left">
          <p className="lg:text-[45px] leading-tight  ">
            <b className="bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent font-bold">
              Blockfy
            </b>
            &nbsp;is a blockchain technology provider designed to turn
            real-world assets into digital opportunities, making wealth
            accessible to everyone around the world.
          </p>
          <div className="py-12 flex flex-row hover:text-blue-500">
            <IconButton
              color="light-blue"
              variant="outlined"
              className="rounded-full hover:text-blue-500"
            >
              <i className="fa-duotone fa-solid fa-play"></i>
            </IconButton>
            <div className="px-12 pt-1">
              <p className="lg:text-[20px] md:text-[12px]">
                Explore the Blockfy Experience
              </p>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 ">
          {/* <Button
            variant="outlined"
            size="sm"
            color="blue"
            className=" float-right lg:inline-block right-0 mr-10"
          >
            <span>Explore Blockfy</span>
          </Button> */}
          <MenuCustomAnimation></MenuCustomAnimation>
          {/* <div className="">
            <iframe
              src="https://giphy.com/embed/WQZpXz8Y8eKgGIxrSP"
              width="100"
              height="auto"
              className="giphy-embed"
              allowFullScreen
            ></iframe>
          </div> */}
        </div>
      </div>
      {/* <video
        autoplay
        loop
        muted
        className="absolute  top-0 left-0 w-auto min-w-full min-h-full max-w-none"
      >
        <source
          src="https://assets.mixkit.co/videos/preview/mixkit-set-of-plateaus-seen-from-the-heights-in-a-sunset-26070-large.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}
    </section>
  );
}
