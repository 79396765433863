import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import { StickyNavbar } from "./pages/header";
import { FooterWithSocialLinks } from "./pages/footer";
import { Home } from "./pages/home";
import { Treasury } from "./pages/treasury";
import { WalletService } from "./pages/walletService";
import { PaymentGateway } from "./pages/paymentGateway";
import { AssetTokenization } from "./pages/asssetToken";
import { About } from "./pages/about";
import { NotFound } from "./pages/notFound404";
import FadeWrapper from "./components/FadeWrapper";
import CursorImage from "./assets/images/Hoi.png";

function App() {
  const styles = {
    cursor: `url(${CursorImage}), auto`, // Custom cursor
  };
  return (
    <div className="App" style={styles}>
      {/* Static Navbar */}
      <StickyNavbar />

      {/* Dynamic Content */}
      <FadeWrapper>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Treasury" element={<Treasury />} />
          <Route path="/walletService" element={<WalletService />} />
          <Route path="/PaymentGateway" element={<PaymentGateway />} />
          <Route path="/AssetTokenization" element={<AssetTokenization />} />
          <Route path="/About" element={<About />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </FadeWrapper>

      <FooterWithSocialLinks />
    </div>
  );
}

export default App;
