import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

const FadeWrapper = ({ children }) => {
  const [fade, setFade] = useState("fade-in");
  const location = useLocation();

  useEffect(() => {
    setFade("fade-out");

    const timer = setTimeout(() => {
      setFade("fade-in");
    }, 1000); // Adjust duration for the fade-out effect

    return () => clearTimeout(timer);
  }, [location]);

  return <div className={fade}>{children}</div>;
};

export default FadeWrapper;
