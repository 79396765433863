import { Button } from "@material-tailwind/react";

export function PaymentGateway() {
  return (
    <section className="mx-auto px-auto py-32 ">
      <div className="flex flex-col md:flex-row px-12 ">
        <div className="w-full text-left">
          <p className="lg:text-[45px] leading-tight  ">
            <b className=" font-bold">Reimagine payments through</b>
          </p>
          <span className=" lg:text-[56px] leading-tight bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent font-bold">
            {" "}
            Blockchain
          </span>
          <p className=" w-[80%] py-8">
            With Blockfy Payment Gateway, you can break free from the
            constraints of legacy financial systems. Our revolutionary platform
            empowers businesses to seamlessly accept, manage, and settle digital
            asset payments, effortlessly operating across any blockchain,
            anywhere in the world.
          </p>
          <div className="py-12 flex flex-row hover:text-blue-500">
            <div className="py-12 flex flex-row hover:text-blue-500">
              <Button
                color="light-blue"
                variant="outlined"
                className="rounded-full text-gray border-2 rounded-lg  hover:text-blue-500"
              >
                Request Demo
              </Button>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 ">
          {/* <div className="">
            <iframe
              src="https://giphy.com/embed/WQZpXz8Y8eKgGIxrSP"
              width="100"
              height="auto"
              className="giphy-embed"
              allowFullScreen
            ></iframe>
          </div> */}
        </div>
      </div>
      {/* <video
        autoplay
        loop
        muted
        className="absolute  top-0 left-0 w-auto min-w-full min-h-full max-w-none"
      >
        <source
          src="https://assets.mixkit.co/videos/preview/mixkit-set-of-plateaus-seen-from-the-heights-in-a-sunset-26070-large.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}
    </section>
  );
}
