import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Tooltip,
  Typography,
} from "@material-tailwind/react";

import blockImage from "../../assets/images/block.jpeg";
import Gelo from "../../assets/images/Gelo.png";
import Nikko from "../../assets/images/Nikko.JPG";
import Paul from "../../assets/images/Paul.jpeg";

export function About() {
  return (
    <>
      <section
        className="relative mt-10 mx-10 px-12 py-12 bg-[#020617] rounded-2xl "
        style={{ backgroundImage: `url(${blockImage})` }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-90 rounded-lg"></div>
        <div className="flex flex-col md:flex-row px-12">
          <div className="w-full text-left px-auto">
            <p className=" relative z-20 text-md leading-tight text-white py-4  ">
              Blockfy Mission
            </p>
            <div className="lg:text-[48px] md:text-[38px]">
              <span className="animate-pulse absolute blur-xl  leading-tight bg-gradient-to-r from-white to-blue-500 bg-clip-text text-transparent font-semibold">
                {" "}
                We’re democratizing access to asset-backed financial systems,
                breaking down barriers to wealth creation.
              </span>
              <p className="  relative  leading-tight bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent font-normal">
                {" "}
                We’re democratizing access to asset-backed financial systems,
                breaking down barriers to wealth creation.
              </p>
            </div>
          </div>

          {/* <div className="">
              <iframe
                src="https://giphy.com/embed/WQZpXz8Y8eKgGIxrSP"
                width="100"
                height="auto"
                className="giphy-embed"
                allowFullScreen
              ></iframe>
            </div> */}
        </div>
        {/* <video
          autoplay
          loop
          muted
          className="absolute  top-0 left-0 w-auto min-w-full min-h-full max-w-none"
        >
          <source
            src="https://assets.mixkit.co/videos/preview/mixkit-set-of-plateaus-seen-from-the-heights-in-a-sunset-26070-large.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video> */}
      </section>

      <div className="animate-pulse py-24 px-12 rounded-lg space-y-2 sm:space-y-8 md:space-y-12">
        <div className="bg-gradient-to-r from-white to-blue-500 p-[2px]"></div>
      </div>

      <section>
        <section className="mx-auto px-auto py-4 ">
          <div className="flex flex-col md:flex-row px-12 ">
            <div className="w-fit text-left  align-center flex-center">
              <div className="border-l-8 border-blue-500 pl-10">
                <p className="lg:text-[18px] leading-tight   ">
                  <b className=" font-semibold"> How Blockfy Began</b>
                </p>
                <span className=" lg:text-[52px] leading-tight bg-gradient-to-r from-teal-400 to-blue-500 bg-clip-text text-transparent font-bold">
                  {" "}
                  In 2023,
                </span>
              </div>
              <div className="lg:mx-10 md:mx-2 text-justify">
                <p className=" px-2 py-8 leading-loose">
                  Nikko Lam, an aspiring entrepreneur, found himself exploring
                  the future of real estate through a thesis he discovered on
                  fractional shares using digital tokens for condotel
                  development. This revelation sparked a broader vision—turning
                  real-world assets into digital opportunities
                </p>
                <p className=" text-lg px-2 py-8  leading-loose">
                  Nikko soon crossed paths with Gelo Wong, and together they saw
                  the potential to revolutionize the way assets are brought
                  on-chain. They teamed up with Paul Soliman, one of the
                  earliest pioneers of blockchain in the Philippines, and
                  realized the immense untapped potential in transforming
                  traditional finance.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="animate-pulse py-24 px-12 rounded-lg space-y-2 sm:space-y-8 md:space-y-12">
        <div className="bg-gradient-to-r from-blue-500 to-white p-[2px]"></div>
      </div>

      <section className="px-8 pb-52">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 ">
          <div className="backdrop-blur-sm bg-white/30 p-4 leading-loose rounded-md">
            <div className="border-l-8 border-blue-500 pl-10">
              <h2 className="text-4xl font-bold leading-tight bg-gradient-to-r from-blue-400 to-teal-500 bg-clip-text text-transparent font-bold">
                The mission was clear:
              </h2>
            </div>
            <div className="lg:mx-10 md:mx-2 text-justify">
              <p className="text-md py-4">
                to make wealth and financial opportunities accessible to
                everyone, regardless of their location or background. The team
                saw a growing shift towards bringing off-chain assets onto the
                blockchain, and with that, the complexity and challenges
                associated with secure, scalable solutions for digitizing these
                assets.
              </p>
              <p className="text-md py-4">
                With over a decade of combined experience in technology and
                blockchain innovation, Nikko, Gelo, and Paul set out to create
                Blockfy—a platform designed to simplify the tokenization of
                real-world assets, enabling seamless access to digital assets
                for users worldwide. Blockfy empowers individuals and
                institutions to securely mint, manage, and trade tokenized
                assets, unlocking the next generation of finance.
              </p>
              <p className="text-md py-4">
                By utilizing cutting-edge blockchain protocols and a deep
                commitment to transparency and security, Blockfy aims to
                transform the financial landscape, making asset ownership and
                wealth generation truly global.
              </p>
            </div>
          </div>

          <div className="flex items-center flex-col justify-evenly">
            <div className="py-4">
              <Card className="w-60 shadow-none hover:border-8 border-blue-500 ease-in duration-300 ">
                <CardHeader
                  floated={false}
                  className="h-auto hover:grayscale ease-in duration-300 hover:skew-y-3"
                >
                  <img src={Nikko} alt="profile-picture" />
                </CardHeader>
                <CardBody className="text-left">
                  <Typography variant="h4" color="blue-gray" className="mb-2 ">
                    Nikko Lam
                  </Typography>
                  <Typography
                    color="blue-gray"
                    className="font-medium"
                    textGradient
                  >
                    Chief Executive Officer, Co- Founder
                  </Typography>
                </CardBody>
                <CardFooter className="flex justify-left gap-7 pt-2">
                  <Tooltip content="Like">
                    <Typography
                      as="a"
                      href="#facebook"
                      variant="lead"
                      color="blue"
                      textGradient
                    >
                      <i className="fab fa-facebook" />
                    </Typography>
                  </Tooltip>
                  <Tooltip content="Follow">
                    <Typography
                      as="a"
                      href="#twitter"
                      variant="lead"
                      color="light-blue"
                      textGradient
                    >
                      <i className="fab fa-twitter" />
                    </Typography>
                  </Tooltip>
                  <Tooltip content="Follow">
                    <Typography
                      as="a"
                      href="#instagram"
                      variant="lead"
                      color="purple"
                      textGradient
                    >
                      <i className="fab fa-instagram" />
                    </Typography>
                  </Tooltip>
                </CardFooter>
              </Card>
            </div>
            <div className="py-4">
              <Card className="w-60 shadow-none hover:border-8 border-blue-500 ease-in duration-300">
                <CardHeader
                  floated={false}
                  className="h-auto hover:grayscale ease-in duration-300 hover:skew-y-3"
                >
                  <img src={Paul} alt="profile-picture" />
                </CardHeader>
                <CardBody className="text-left">
                  <Typography variant="h4" color="blue-gray" className="mb-2">
                    Paul Soliman
                  </Typography>
                  <Typography
                    color="blue-gray"
                    className="font-medium"
                    textGradient
                  >
                    Chief Technology Officer, Co-Founder
                  </Typography>
                </CardBody>
                <CardFooter className="flex justify-left gap-7 pt-2">
                  <Tooltip content="Like">
                    <Typography
                      as="a"
                      href="#facebook"
                      variant="lead"
                      color="blue"
                      textGradient
                    >
                      <i className="fab fa-facebook" />
                    </Typography>
                  </Tooltip>
                  <Tooltip content="Follow">
                    <Typography
                      as="a"
                      href="#twitter"
                      variant="lead"
                      color="light-blue"
                      textGradient
                    >
                      <i className="fab fa-twitter" />
                    </Typography>
                  </Tooltip>
                  <Tooltip content="Follow">
                    <Typography
                      as="a"
                      href="#instagram"
                      variant="lead"
                      color="purple"
                      textGradient
                    >
                      <i className="fab fa-instagram" />
                    </Typography>
                  </Tooltip>
                </CardFooter>
              </Card>
            </div>
          </div>

          {/* Right side */}

          <div className="p-4 leading-loose flex items-center justify-evenly">
            <div className="py-4">
              <Card className="w-60 shadow-none hover:border-8 border-blue-500 ease-in duration-300">
                <CardHeader
                  floated={false}
                  className="h-auto hover:grayscale ease-in duration-300 hover:skew-y-3"
                >
                  <img src={Gelo} alt="profile-picture" />
                </CardHeader>
                <CardBody className="text-left">
                  <Typography variant="h4" color="blue-gray" className="mb-2">
                    Gelo Wong
                  </Typography>
                  <Typography
                    color="blue-gray"
                    className="font-medium"
                    textGradient
                  >
                    Chief Operating Officer, Co-Founder
                  </Typography>
                </CardBody>
                <CardFooter className="flex justify-left gap-7 pt-2">
                  <Tooltip content="Like">
                    <Typography
                      as="a"
                      href="#facebook"
                      variant="lead"
                      color="blue"
                      textGradient
                    >
                      <i className="fab fa-facebook" />
                    </Typography>
                  </Tooltip>
                  <Tooltip content="Follow">
                    <Typography
                      as="a"
                      href="#twitter"
                      variant="lead"
                      color="light-blue"
                      textGradient
                    >
                      <i className="fab fa-twitter" />
                    </Typography>
                  </Tooltip>
                  <Tooltip content="Follow">
                    <Typography
                      as="a"
                      href="#instagram"
                      variant="lead"
                      color="purple"
                      textGradient
                    >
                      <i className="fab fa-instagram" />
                    </Typography>
                  </Tooltip>
                </CardFooter>
              </Card>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
