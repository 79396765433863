import { Button } from "@material-tailwind/react";

export function Treasury() {
  return (
    <section className="mx-auto px-auto py-32 bg-black">
      <div className="flex flex-col md:flex-row px-12 ">
        <div className="w-full text-left">
          <b className=" relative  lg:text-6xl text-blue-400 border-none  leading-tight font-semibold ">
            The simplest way to manage your{" "}
            <span className=" leading-loose bg-gradient-to-r from-teal-500 to-cyan-500 bg-clip-text text-transparent blur-md underline   hover:blur-none ease-in duration-300 active:blur-none">
              Treasury
            </span>
          </b>

          <p className="text-2xl  text-white  leading-tight font-thin py-10">
            A powerful and simple dashboard for your treasury needs
          </p>
          <div className="py-12 flex flex-row hover:text-blue-500 ">
            <Button
              color="light-blue"
              variant="outlined"
              className="rounded-full text-white border-2 rounded-lg border-sky-200 shadow-[0_0_2px_#fff,inset_0_0_2px_#fff,0_0_5px_#08f,0_0_15px_#08f,0_0_30px_#08f] hover:text-blue-500"
            >
              Request Demo
            </Button>
          </div>
        </div>

        <div className="w-full md:w-1/2 ">
          {/* <div className="">
            <iframe
              src="https://giphy.com/embed/WQZpXz8Y8eKgGIxrSP"
              width="100"
              height="auto"
              className="giphy-embed"
              allowFullScreen
            ></iframe>
          </div> */}
        </div>
      </div>
      {/* <video
        autoplay
        loop
        muted
        className="absolute  top-0 left-0 w-auto min-w-full min-h-full max-w-none"
      >
        <source
          src="https://assets.mixkit.co/videos/preview/mixkit-set-of-plateaus-seen-from-the-heights-in-a-sunset-26070-large.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}
    </section>
  );
}
