import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
} from "@material-tailwind/react";
import { Link } from "react-router-dom";

export function MenuCustomAnimation() {
  return (
    <Menu
      animate={{
        mount: { y: 0 },
        unmount: { y: 25 },
      }}
    >
      <MenuHandler>
        <Button
          variant="outlined"
          size="sm"
          color="blue"
          className=" float-right lg:inline-block right-0 mr-10"
        >
          {" "}
          Explore Blockfy
        </Button>
      </MenuHandler>
      <MenuList>
        <MenuItem>Blog</MenuItem>
        <MenuItem>
          <Link to={"/About"}>About Blockfy</Link>
        </MenuItem>
        <MenuItem> Careers</MenuItem>
      </MenuList>
    </Menu>
  );
}
