import {
  Button,
  Timeline,
  TimelineBody,
  TimelineConnector,
  TimelineHeader,
  TimelineIcon,
  TimelineItem,
  Typography,
} from "@material-tailwind/react";

export function AssetTokenization() {
  return (
    <section className="mx-auto px-auto py-32 ">
      <div className="flex flex-col md:flex-row px-12 ">
        <div className="w-full text-left">
          <p className="lg:text-[45px] leading-tight  ">
            <b className=" font-semibold">
              Simplest way to tokenize your real assets,
            </b>
          </p>
          <span className=" lg:text-[56px] leading-tight bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent font-bold">
            {" "}
            Redefining Ownership
          </span>
          <p className=" w-[80%] py-8">
            Mint, manage, and transfer tokenized assets with groundbreaking
            Blockfy Launchpad platform ensuring transparency and security
            throughout the transaction.
          </p>
          <div className="py-12 flex flex-row hover:text-blue-500">
            <div className="py-12 flex flex-row hover:text-blue-500">
              <Button
                color="light-blue"
                variant="outlined"
                className="rounded-full text-gray border-2 rounded-lg  hover:text-blue-500"
              >
                Request Demo
              </Button>
            </div>
          </div>
        </div>

        <div className="w-full md:w-1/2 ">
          {/* <div className="">
            <iframe
              src="https://giphy.com/embed/WQZpXz8Y8eKgGIxrSP"
              width="100"
              height="auto"
              className="giphy-embed"
              allowFullScreen
            ></iframe>
          </div> */}
        </div>
      </div>
      {/* <video
        autoplay
        loop
        muted
        className="absolute  top-0 left-0 w-auto min-w-full min-h-full max-w-none"
      >
        <source
          src="https://assets.mixkit.co/videos/preview/mixkit-set-of-plateaus-seen-from-the-heights-in-a-sunset-26070-large.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video> */}

      <div className="animate-pulse py-24 px-12 rounded-lg space-y-2 sm:space-y-8 md:space-y-12">
        <div className="bg-gradient-to-r from-blue-500 to-white p-[2px]"></div>
      </div>

      <section className="px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          <div className="bg-white p-4 leading-loose">
            <div className="border-l-8 border-blue-500 pl-5 mb-5">
              <h2 className="text-lg bg-gradient-to-r from-blue-400 to-teal-500 bg-clip-text text-transparent font-bold font-bold py-12">
                Blockfy Tokenization Launchpad
              </h2>
            </div>
            <p className="text-md">
              is engineered for{" "}
              <span className="bg-gradient-to-r from-teal-400 to-blue-500 bg-clip-text text-transparent font-bold">
                Web3
              </span>
              , seamlessly bridging real-world assets and digital innovation. By
              leveraging blockchain technology, Blockfy unlocks limitless
              opportunities for businesses and investors, transforming tangible
              assets into secure, tradable digital tokens.
            </p>
          </div>
          <div className="bg-white py-12 leading-loose">
            <span className=" text-white">.</span>
            <Timeline>
              <TimelineItem>
                <TimelineConnector />
                <TimelineHeader className="h-6">
                  <TimelineIcon color="blue" />
                  <Typography
                    variant="h3"
                    color="blue-gray"
                    className="leading-none"
                  >
                    RealEstate
                  </Typography>
                </TimelineHeader>
                <TimelineBody className="pb-8">
                  <Typography
                    variant="small"
                    color="gray"
                    className="font-normal text-gray-600"
                  >
                    Bring property ownership on-chain for easier management and
                    investment.
                  </Typography>
                </TimelineBody>
              </TimelineItem>
              <TimelineItem>
                <TimelineConnector />
                <TimelineHeader className="h-6">
                  <TimelineIcon color="blue" />
                  <Typography
                    variant="h3"
                    color="blue-gray"
                    className="leading-none"
                  >
                    Precious Metals
                  </Typography>
                </TimelineHeader>
                <TimelineBody className="pb-8">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-gray-600"
                  >
                    Tokenize gold, silver, and other valuable commodities for
                    global trading.
                  </Typography>
                </TimelineBody>
              </TimelineItem>
              <TimelineItem>
                <TimelineHeader className="h-6">
                  <TimelineIcon color="blue" />
                  <Typography
                    variant="h3"
                    color="blue-gray"
                    className="leading-none"
                  >
                    Energy
                  </Typography>
                </TimelineHeader>
                <TimelineBody>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-gray-600"
                  >
                    Digitally represent renewable energy credits, oil, or gas.
                  </Typography>
                </TimelineBody>
              </TimelineItem>
            </Timeline>
          </div>
          <div className="py-12 bg-white leading-loose ">
            <span className=" text-white">.</span>
            <Timeline>
              <TimelineItem>
                <TimelineConnector />
                <TimelineHeader className="h-6">
                  <TimelineIcon color="blue" />
                  <Typography
                    variant="h3"
                    color="blue-gray"
                    className="leading-none"
                  >
                    Transportation
                  </Typography>
                </TimelineHeader>
                <TimelineBody className="pb-8">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-gray-600"
                  >
                    From vehicles to logistics infrastructure, make
                    transportation assets accessible
                  </Typography>
                </TimelineBody>
              </TimelineItem>
              <TimelineItem>
                <TimelineConnector />
                <TimelineHeader className="h-6">
                  <TimelineIcon color="blue" />
                  <Typography
                    variant="h3"
                    color="blue-gray"
                    className="leading-none"
                  >
                    Creative Assets
                  </Typography>
                </TimelineHeader>
                <TimelineBody className="pb-8">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-gray-600"
                  >
                    Unlock new value from intellectual property, art, and media.
                  </Typography>
                </TimelineBody>
              </TimelineItem>
              <TimelineItem>
                <TimelineHeader className="h-6">
                  <TimelineIcon color="blue" />
                  <Typography
                    variant="h3"
                    color="blue-gray"
                    className="leading-none"
                  >
                    Cell Towers
                  </Typography>
                </TimelineHeader>
                <TimelineBody>
                  <Typography
                    variant="small"
                    color="gray"
                    className="font-normal text-gray-600"
                  >
                    Convert telecommunication infrastructure into fractional
                    ownership opportunities.
                  </Typography>
                </TimelineBody>
              </TimelineItem>
            </Timeline>
          </div>
        </div>

        <p className="text-md px-4">
          It uses{" "}
          <span className="bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent font-bold">
            {" "}
            decentralized smart contracts{" "}
          </span>{" "}
          and{" "}
          <span className="bg-gradient-to-r from-blue-400 to-blue-500 bg-clip-text text-transparent font-bold">
            secure cryptographic proofs
          </span>{" "}
          to ensure that tokenized assets are authenticated, traceable, and
          easily transferable. Interoperability with existing Web3 networks
          provides a dynamic platform for innovative applications and use cases.
        </p>
      </section>
    </section>
  );
}
